exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-classes-jsx": () => import("./../../../src/pages/classes.jsx" /* webpackChunkName: "component---src-pages-classes-jsx" */),
  "component---src-pages-event-jsx": () => import("./../../../src/pages/event.jsx" /* webpackChunkName: "component---src-pages-event-jsx" */),
  "component---src-pages-exam-jsx": () => import("./../../../src/pages/exam.jsx" /* webpackChunkName: "component---src-pages-exam-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-register-jsx": () => import("./../../../src/pages/register.jsx" /* webpackChunkName: "component---src-pages-register-jsx" */),
  "component---src-pages-schedule-jsx": () => import("./../../../src/pages/schedule.jsx" /* webpackChunkName: "component---src-pages-schedule-jsx" */)
}

